<template>
  <ResourceDialog
    ref="dialog"
    v-on="$listeners"
    :rules="rules"
    :defaults="defaults"
    entity-name="Material"
    collection-name="materials"
  >
    <template slot-scope="{ id, data }">
      <!-- name field -->
      <ElFormItem label="Nume" prop="name">
        <ElInput autofocus v-model="data.name" />
      </ElFormItem>
      <!-- category field -->
      <ElFormItem label="Categorie" prop="categoryId">
        <ResourceSelect
          v-model="data.categoryId"
          collection-name="materialCategories"
          placeholder="Selecteaza"
          class="block"
        />
        <ElButton size="mini" @click="$refs.categoryDialog.add()"
          >Adauga Categorie</ElButton
        >
      </ElFormItem>
      <!-- supplier field -->
      <ElFormItem label="Furnizor" prop="supplierId">
        <ResourceSelect
          v-model="data.supplierId"
          collection-name="suppliers"
          placeholder="Selecteaza"
          class="block"
        />
        <ElButton size="mini" @click="$refs.supplierDialog.add()"
          >Adauga Furnizor</ElButton
        >
      </ElFormItem>
      <!-- fsc field -->
      <ElFormItem label="FSC Mix" prop="fsc">
        <ElSwitch v-model="data.fsc" />
      </ElFormItem>
      <!-- basePrice field -->
      <ElFormItem label="Pret de baza" prop="basePrice">
        <ElInput
          type="number"
          @change="updatePrice(data)"
          v-model="data.basePrice"
          :min="0"
          :precision="2"
          style="width: auto; display: block;"
        />
      </ElFormItem>
      <!-- margin field -->
      <ElFormItem label="Adaos (%)" prop="margin">
        <ElInput
          type="number"
          @change="updatePrice(data)"
          v-model="data.margin"
          :min="0"
          :precision="2"
          style="width: auto; display: block;"
        />
      </ElFormItem>
      <!-- price field -->
      <ElFormItem label="Pret" prop="price">
        <ElInput
          disabled
          type="number"
          v-model="data.price"
          :min="0"
          :precision="2"
          style="width: auto; display: block;"
        />
      </ElFormItem>
      <!-- currentQuantity field -->
      <ElFormItem label="Cantitate Curenta" prop="currentQuantity">
        <ElInputNumber
          v-model="data.currentQuantity"
          :min="0"
          :step="100"
          style="width: auto; display: block;"
        />
      </ElFormItem>
      <!-- minimumQuantity field -->
      <ElFormItem label="Cantitate Minima" prop="minimumQuantity">
        <ElInputNumber
          v-model="data.minimumQuantity"
          :min="0"
          :step="100"
          style="width: auto; display: block;"
        />
      </ElFormItem>
      <!-- orderedQuantity field -->
      <ElFormItem v-if="id" label="Cantitate Comandata" prop="orderedQuantity">
        <ElInputNumber
          v-model="data.orderedQuantity"
          :min="0"
          :step="100"
          style="width: auto; display: block;"
        />
      </ElFormItem>
      <!-- orderedAt field -->
      <ElFormItem v-if="id" label="Data Comanda" prop="orderedAt">
        <ElDatePicker
          :value="data.orderedAt && data.orderedAt.toDate()"
          @input="data.orderedAt = $firestore.Timestamp.fromDate($event)"
          type="datetime"
          format="dd-MM-yyyy HH:mm"
          style="width: auto; display: block;"
        />
      </ElFormItem>
      <!-- width field -->
      <ElFormItem label="Latime" prop="width">
        <ElInputNumber v-model="data.width" />
      </ElFormItem>
      <!-- height field -->
      <ElFormItem label="Inaltime" prop="height">
        <ElInputNumber v-model="data.height" />
      </ElFormItem>
      <!-- weight field -->
      <ElFormItem label="Grosime" prop="weight">
        <ElInputNumber v-model="data.weight" />
      </ElFormItem>
      <!-- nested dialogs -->
      <MaterialCategoryDialog
        ref="categoryDialog"
        @insert="categoryRef => $set(data, 'categoryId', categoryRef.id)"
      />
      <SupplierDialog
        ref="supplierDialog"
        @insert="supplierRef => $set(data, 'supplierId', supplierRef.id)"
      />
    </template>
  </ResourceDialog>
</template>

<script>
import SupplierDialog from "@/components/suppliers/SupplierDialog";
import ResourceDialogMixin from "@/mixins/ResourceDialogMixin";
import MaterialCategoryDialog from "@/components/materials/MaterialCategoryDialog";

export default {
  mixins: [ResourceDialogMixin],
  components: {
    SupplierDialog,
    MaterialCategoryDialog
  },
  computed: {
    rules() {
      return {
        name: [
          {
            required: true,
            message: "Numele este obligatoriu",
            trigger: "blur"
          }
        ],
        categoryId: [
          {
            required: true,
            message: "Categoria este obligatorie",
            trigger: "change"
          }
        ],
        supplierId: [
          {
            required: true,
            message: "Furnizorul este obligatoriu",
            trigger: "change"
          }
        ],
        price: [
          {
            required: true,
            message: "Pretul este obligatoriu",
            trigger: "blur"
          }
        ],
        currentQuantity: [
          {
            required: true,
            message: "Cantitatea curenta este obligatorie",
            trigger: "blur"
          }
        ],
        minimumQuantity: [
          {
            required: true,
            message: "Cantitatea minima este obligatorie",
            trigger: "blur"
          }
        ]
      };
    },
    defaults() {
      return {
        basePrice: 0,
        margin: 30,
        price: 0,
        currentQuantity: 0,
        minimumQuantity: 0,
        orderedQuantity: 0,
        width: 0,
        height: 0,
        weight: 0
      };
    }
  },
  methods: {
    updatePrice(data) {
      data.price = data.basePrice * (1 + data.margin / 100);
      data.price = Math.floor(data.price * 1000) / 1000;
    }
  }
};
</script>
