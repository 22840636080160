<template>
  <div>
    <Navigation />
    <div class="text-center h4" style="color:red">
      Modul in lucru
    </div>
    <div>
      <div class="block">
        <span class="demonstration mx-5">Alege perioada</span>

        <el-date-picker
          v-model="value1"
          type="datetimerange"
          range-separator="To"
          start-placeholder="Start date"
          end-placeholder="End date"
        >
        </el-date-picker>
      </div>
    </div>

    <el-tabs v-model="tabName" class="pa-5" @tab-click="handleClick">
    <el-tab-pane label="General" name="General">
        Line Grafic cu incasare / vs numar de lucrari / luna
        Te ajuta sa prioritizei lucrari daca doresti o luna mai profitabila.
        Q: Care sunt ofertele care nu ajung lucrari si de ce ?
        Q: ce lucrari aduc mai mult profit & stabilitate ?
        Q: care sabloane.
        Q: Care lucrari blocheaza sectia si aduc putin profit ?
    </el-tab-pane>
      <el-tab-pane label="Financiar" name="Financiar">
        <div>
          Finalizate: 50 lucrari
        </div>
        <div>
          Total incasat: 100 lei
        </div>
        <div>
          Total tva: 100 lei
        </div>
      </el-tab-pane>
      <el-tab-pane label="Clienti" name="Clienti">
        Top clienti / top sabloane
      </el-tab-pane>
      <el-tab-pane label="Activitate sectie" name="Activitate">
        Activitate Productie
        <div>
          Oferte: 0
        </div>
        <div>
          Comenzi: 0
        </div>
        <div>
          In lucru: 0
        </div>
        <div>
          Finalizate: 0
        </div>
      </el-tab-pane>
      <el-tab-pane label="Productivitate sectie" name="Productivitate">
        Care este timpul de "idle" pe posturi de lucru. / per angajat
      </el-tab-pane>
      
    </el-tabs>
  </div>
</template>
<script>
export default {
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: "Last week",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last month",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last 3 months",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      value1: [new Date(), new Date()],
      tabName: "Financiar",
    };
  },
  methods: {
    handleClick() {
      this.$firestore()
        .collection("projects")
        .where("nr", ">", 0)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, " => ", doc.data());
          });
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    },
  },
};
</script>
