<template>
  <ElDialog
    v-if="id"
    :visible.sync="visible"
    title="Plaseaza Comanda"
    width="600px"
    append-to-body
  >
    <ElForm
      ref="form"
      label-width="140px"
      :model="data"
      :rules="rules"
      @submit.native.prevent
    >
      <!-- orderedQuantity field -->
      <ElFormItem label="Cantitate Comandata" prop="orderedQuantity">
        <ElInputNumber
          autofocus
          v-model="data.orderedQuantity"
          :min="0"
          :step="100"
          style="width: auto; display: block;"
        />
      </ElFormItem>
    </ElForm>
    <span slot="footer" class="dialog-footer">
      <ElButton type="primary" :loading="loadingSubmit" @click="handleSubmit"
        >Plaseaza Comanda</ElButton
      >
    </span>
  </ElDialog>
</template>

<script>
export default {
  data() {
    return {
      id: null,
      data: null,
      visible: false,
      loadingSubmit: false
    };
  },
  computed: {
    rules() {
      return {
        orderedQuantity: [
          {
            required: true,
            message: "Cantitatea comandata este obligatorie",
            trigger: "blur"
          }
        ]
      };
    }
  },
  methods: {
    add(id) {
      this.id = id;
      this.data = {};
      this.open();
    },
    open() {
      this.visible = true;
      this.$nextTick(() => {
        this.$refs.form.clearValidate();
      });
    },
    close() {
      this.visible = false;
    },
    async handleSubmit() {
      const valid = await this.$refs.form.validate();
      if (!valid) return false;

      try {
        this.loadingSubmit = true;
        await this.$firestore()
          .collection("materials")
          .doc(this.id)
          .update({
            ...this.data,
            orderedAt: this.$firestore.FieldValue.serverTimestamp(),
            updatedAt: this.$firestore.FieldValue.serverTimestamp()
          });
        this.close();
      } catch (error) {
        this.$message.error("Oops, a intervenit o eroare.");
      } finally {
        this.loadingSubmit = false;
      }
    }
  }
};
</script>
