<template>
  <ResourceDialog
    ref="dialog"
    v-on="$listeners"
    :rules="rules"
    entity-name="Categorie"
    collection-name="materialCategories"
  >
    <template slot-scope="{ data }">
      <!-- name field -->
      <ElFormItem label="Nume" prop="name">
        <ElInput v-model="data.name" />
      </ElFormItem>
    </template>
  </ResourceDialog>
</template>

<script>
import ResourceDialogMixin from "@/mixins/ResourceDialogMixin";

export default {
  mixins: [ResourceDialogMixin],
  computed: {
    rules() {
      return {
        name: [
          {
            required: true,
            message: "Numele este obligatoriu",
            trigger: "blur"
          }
        ]
      };
    }
  }
};
</script>
