var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Navigation'),_c('div',{staticClass:"pa-5 flex space-between"},[_c('MaterialFilters'),_c('ElButton',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$refs.materialDialog.add()}}},[_vm._v("Adauga Material")])],1),_c('ResourceTable',{attrs:{"query":_vm.query,"filter":_vm.filter,"default-sort":{ prop: 'computed.availability' },"row-class-name":_vm.getRowClassName}},[_c('ElTableColumn',{attrs:{"type":"index","width":"50"}}),_c('ElTableColumn',{attrs:{"prop":"data.name","label":"Nume","width":"300","sortable":""}}),_c('ElTableColumn',{attrs:{"prop":"data.fsc","label":"FSC Mix","sortable":"","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return (row.data.fsc)?_c('ElTag',{attrs:{"size":"mini","type":"success"}},[_vm._v("FSC Mix")]):_vm._e()}}],null,true)}),_c('ElTableColumn',{attrs:{"prop":"data.price","label":"Pret","width":"100","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.data.price)+" lei")]}}])}),_c('ElTableColumn',{attrs:{"prop":"computed.availability","label":"Disponibilitate","sortable":"","sort-by":_vm.getRowAvailability},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.data.currentQuantity)+" / "+_vm._s(row.data.minimumQuantity)+" "),(
            row.data.currentQuantity <= row.data.minimumQuantity &&
              row.data.minimumQuantity !== 0
          )?_c('el-alert',{attrs:{"title":"stoc material sub limita","type":"error"}}):_vm._e()]}}])}),_c('ElTableColumn',{attrs:{"label":"Comanda"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return _c('div',{},[(row.data.orderedQuantity && row.data.orderedAt)?_c('div',[_c('div',[_vm._v(_vm._s(row.data.orderedQuantity))]),_c('TimeAgo',{attrs:{"date":row.data.orderedAt.toDate()}}),_c('ElButton',{attrs:{"size":"mini","icon":"el-icon-close"},on:{"click":function($event){return _vm.receiveCurrentOrder(row)}}},[_vm._v("Receptioneaza")])],1):_c('ElButton',{attrs:{"size":"mini","icon":"el-icon-plus"},on:{"click":function($event){return _vm.$refs.materialOrderDialog.add(row.id)}}},[_vm._v("Plaseaza")])],1)}}])}),_c('ElTableColumn',{attrs:{"align":"right","width":"50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('ElButton',{attrs:{"size":"mini","icon":"el-icon-edit","circle":""},on:{"click":function($event){return _vm.$refs.materialDialog.edit(row.id)}}})]}}])})],1),_c('MaterialDialog',{ref:"materialDialog"}),_c('MaterialOrderDialog',{ref:"materialOrderDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }